import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Header from "../../components/header";
import ChevronRightOutlined from "@mui/icons-material/ChevronRightOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  getCurrentDateTime,
  formatCustomDate,
  formatCustomTime,
} from "../../utls/dateTimeUtls";

import {
  AccountCircleRounded,
  AlarmRounded,
  ArrowBackRounded,
  AssignmentIndRounded,
  AssignmentRounded,
  EmailRounded,
  LanguageRounded,
  Person2Outlined,
  PhoneForwardedRounded,
  PhoneIphoneRounded,
  ScheduleRounded,
  SourceRounded,
  SpeedRounded,
  TtyRounded,
} from "@mui/icons-material";

import { useState } from "react";
import {
  GetCallStatusData,
  GetLeadsById,
  GetPrefferedCountries,
  GiveUpLeadData,
  UpdateLeadStatus,
  UpdateStudentProfile,
} from "../../data/repos/userRepo";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { tokens } from "../../components/theme";
import FactCard from "../../components/factCard";
import TimeLine from "../../components/timeline";
import StudentProfileUpdateForm from "../../components/studentProfileUpdateForm";
import ProfileCard from "../../components/profileCard";
import NameEdit from "../../components/nameEdit";

const UserDetails = () => {
  const { id } = useParams();
  const studentId = parseInt(id, 10);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleGoBack = () => {
    navigate(-1);
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [formValues, setFormValues] = useState({});

  const [callStatusResponseData, setCallStatusResponseData] = useState([]);
  const [countryResponseData, setCountryResponseData] = useState([]);
  const [submitResponsedata, setSubmitResponsedata] = useState({});
  const [clickedLeadInfo, setClickedLeadInfo] = useState({});
  const [followupCount, setFollowupCount] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [assignedOn, setAssignedOn] = useState("");
  const [callScheduled, setCallScheduled] = useState("");
  const [possibility, setPossibility] = useState("");
  const [source, setSource] = useState("");
  const [studentProfileData, setStudentProfileData] = useState({});

  const [comments, setComments] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [nameEditMode, setNameEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };
  const nameToggleEditMode = () => {
    setNameEditMode(!nameEditMode);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const initialValues = {
    callStatus: "",
    callStatusName: "",
    rntReason: "",
    rntReasonName: "",
    rescheduleDate: "",
    rescheduleTime: "",
    country: "",
    countryName: "",
    remarks: "",
  };

  const boxStyle = {
    flex: "1", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "10px",
    maxHeight: "90vh",
    overflowY: "auto",
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const previewBoxStyle = {
    margin: "0px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
  };

  const innerBox = {
    flex: "1", // Each child takes up 50% of the parent
    // border: '1px solid #000',  // Add a border for better visibility
    margin: "15px", // Add margin for spacing between child boxes
    boxSizing: "border-box", // Include border in box size calculation
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  };

  const rntReasonData = [
    {
      id: 1,
      name: "Called and no answer from the other end",
    },

    {
      id: 2,
      name: "Call cut from other end",
    },

    {
      id: 3,
      name: "Call taken and but requested to call back",
    },

    // Add more cards as needed
  ];

  const {
    data: leads,
    isLoading: isLeadsLoading,
    error: isLeadsError,
  } = useQuery(
    ["leads", studentId], // Use an array to specify dependencies (leads and id)
    () => GetLeadsById(studentId), // Pass a function that returns the promise
    {
      onSuccess: (leads, variables, context) => {
        setClickedLeadInfo(leads?.responsedata);
        const comments = formattedComments(leads?.responsedata.comments);
        setComments(comments);
        setFollowupCount(leads?.responsedata.followupcount.Int64);
        setCountryResponseData(leads?.responsedata.country);
        // leads?.responsedata.updates.prefferedcountry
        setStudentProfileData(leads?.responsedata?.edprofile || {});

        setLeadStatus(leads?.responsedata.updates.leadstatus);
        const assignedDate = leads?.responsedata.updates.assignedon.String;
        const formatedDate = formatCustomDate(new Date(assignedDate));
        setAssignedOn(assignedDate);
        if (leads?.responsedata.updates.isrescheduled === 1) {
          setCallScheduled("Yes");
        } else {
          setCallScheduled("No");
        }

        const probability = leads?.responsedata.updates.possiblility;
        const possibilityMap = {
          Hot: "Hot",
          Warm: "Warm",
        };
        if (probability !== "" || probability !== undefined) {
          setPossibility(possibilityMap[probability] ?? "Cold");
        }
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  useEffect(() => {
    if (clickedLeadInfo && clickedLeadInfo.name === "") {
      setNameEditMode(true);
    } else {
      setNameEditMode(false);
    }

    // Check if studentProfileData exists and its id is 0
    if (studentProfileData && studentProfileData.id === 0) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [clickedLeadInfo, studentProfileData]);

  const { callStatus, isCallStatusLoading, isCallStatusError } = useQuery(
    "callStatus",
    GetCallStatusData,
    {
      onSuccess: (callStatusData, variables, context) => {
        setCallStatusResponseData(callStatusData?.responsedata);
      },
      onError: (error) => {
        // Handle errors here
        console.error("Error:", error.message);
      },
    }
  );

  const handleFormSubmit = (values) => {
    const commentsArray = [];
    const comment1 = {
      message: formValues.callStatusName,
    };
    const comment2 = {
      message: formValues.rntReasonName,
    };

    const comment3 = {
      message: values.remarks,
    };

    if (values.remarks === "") {
      formValues.rntReasonName === undefined
        ? commentsArray.push(comment1)
        : commentsArray.push(comment1, comment2);
    } else {
      formValues.rntReasonName === undefined
        ? commentsArray.push(comment1, comment3)
        : commentsArray.push(comment1, comment2, comment3);
    }

    const isrescheduled = values.rescheduleDate === "" ? 0 : 1;
    const staffId = JSON.parse(localStorage.getItem("loginInfo")).id;

    const updateRequestObj = {
      leadid: studentId,
      leadstatus: formValues.callStatusName,
      isrescheduled: isrescheduled,
      rescheduledate: values.rescheduleDate,
      rescheduletime: values.rescheduleTime,
      prefferedcountry: values.country,
      comments: commentsArray,
      possiblility: values.possiblility,
      staffid: staffId,
      followup: 1,
    };

    console.log("requestObj:", updateRequestObj);

    if (studentId !== undefined && studentId !== null && studentId !== "") {
      fetchData(updateRequestObj);
    } else {
      toast.error("invalid operation!");
    }
  };

  const giveUpLead = (values) => {
   
    const commentsArray = [];
    const comment1 = {
      message: formValues.callStatusName,
    };
    const comment2 = {
      message: formValues.rntReasonName,
    };

    const comment3 = {
      message: values.remarks,
    };

    if (values.remarks === "" || values.remarks === undefined) {
      formValues.rntReasonName === undefined
        ? commentsArray.push(comment1)
        : commentsArray.push(comment1, comment2);
    } else {
      formValues.rntReasonName === undefined
        ? commentsArray.push(comment1, comment3)
        : commentsArray.push(comment1, comment2, comment3);
    }

    // const isrescheduled = values.rescheduleDate === "" ? 0 : 1;
    const staffId = JSON.parse(localStorage.getItem("loginInfo")).id;

    const updateRequestObj = {
      leadid: studentId,
      leadstatus: formValues.callStatusName,
      isrescheduled: 0,
      rescheduledate: "",
      rescheduletime: "",
      prefferedcountry: values.country,
      comments: commentsArray,
      possiblility: values.possiblility,
      staffid: staffId,
      followup: 1,
    };

    console.log("GiveUp requestObj:", updateRequestObj);

    if (studentId !== undefined && studentId !== null && studentId !== "") {
      giveUpLeadRequest.mutate(updateRequestObj);
    } else {
      toast.error("invalid operation!");
    }
  };

  const mutation = useMutation(UpdateLeadStatus, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      setSubmitResponsedata(data?.submitResponsedata);
      toast.success(data.responsedescription);
      console.log("Attempting to invalidate queries for:", [
        "leads",
        studentId,
      ]);
      queryClient.invalidateQueries(["leads", studentId]);
    },
    onError: (error) => {
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });

  const giveUpLeadRequest = useMutation(GiveUpLeadData, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      setSubmitResponsedata(data?.submitResponsedata);
      toast.success(data.responsedescription);
      console.log("Attempting to invalidate queries for:", [
        "give up leads",
        studentId,
      ]);
      queryClient.invalidateQueries(["leads", studentId]);
    },
    onError: (error) => {
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });

  const fetchData = (userData) => {
    mutation.mutate(userData);
  };

  const formattedComments = (comments) => {
    // Check if comments is not null and is an array
    if (comments && Array.isArray(comments)) {
      const groupedComments = {};

      comments.forEach((comment) => {
        const time = comment.time;

        if (time in groupedComments) {
          groupedComments[time].push(comment);
        } else {
          groupedComments[time] = [comment];
        }
      });

      const resultArray = Object.values(groupedComments);

      return resultArray;
    } else {
      // Handle the case where comments is null or not an array
      console.error("Invalid comments data:", comments);
      return [];
    }
  };
  console.log("Last Edit Mode:", studentProfileData.id);

  return (
    <div>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ flex: "2 0 66.66%" }}>
          <Box>
            <Box sx={{ p: "20px", display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={handleGoBack}
                sx={{ color: "#000000", mr: "15px" }}
              >
                {<ArrowBackRounded />}
              </IconButton>
              <AccountCircleRounded
                sx={{
                  fontSize: 20,
                  color: colors.textcolor[100],
                  marginRight: "5px",
                }}
              />
              {nameEditMode ? (
                <NameEdit studentIdNumber = {studentId}/>
              ) : (
                <Typography
                  variant="h5"
                  color={colors.bg[100]}
                  fontWeight="bold"
                  sx={{ m: "0 10px 0 0" }}
                >
                  {clickedLeadInfo.name}
                </Typography>
              )}
              ;
              <Divider
                sx={{
                  mx: "15px",
                  width: "1px",
                  height: "20px",
                  backgroundColor: "#000000",
                }}
              />
              <PhoneIphoneRounded
                sx={{
                  color: colors.textcolor[100],
                  marginRight: "5px",
                }}
              />
              <Typography
                variant="h5"
                fontWeight="bold"
                color={colors.textcolor[100]}
              >
                {clickedLeadInfo.mobile}
              </Typography>
              <Divider
                sx={{
                  mx: "15px",
                  width: "1px",
                  height: "20px",
                  backgroundColor: "#000000",
                }}
              />
              <EmailRounded
                sx={{
                  fontSize: 20,
                  color: colors.textcolor[100],
                  marginRight: "5px",
                }}
              />
              <Typography
                variant="h5"
                fontWeight="bold"
                color={colors.textcolor[100]}
              >
                {clickedLeadInfo.email}
              </Typography>
              <Divider
                sx={{
                  mx: "15px",
                  width: "1px",
                  height: "20px",
                  backgroundColor: "#000000",
                }}
              />
              <SpeedRounded
                sx={{
                  fontSize: 20,
                  color: colors.textcolor[100],
                  marginRight: "5px",
                }}
              />
              <Typography
                variant="h5"
                fontWeight="bold"
                color={
                  possibility === "Hot"
                    ? "#f44336"
                    : possibility === "Warm"
                    ? "#ff9800"
                    : "#03a9f4"
                }
              >
                {possibility}
              </Typography>
              <Divider
                sx={{
                  mx: "15px",
                  width: "1px",
                  height: "20px",
                  backgroundColor: "#000000",
                }}
              />
              <SourceRounded
                sx={{
                  fontSize: 20,
                  color: colors.textcolor[100],
                  marginRight: "5px",
                }}
              />
              <Typography
                variant="h5"
                fontWeight="bold"
                color={colors.textcolor[100]}
              >
                {clickedLeadInfo.source}
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                color={colors.textcolor[100]}
              >
                -{clickedLeadInfo.sourceinfo}
              </Typography>
              <Divider
                sx={{
                  mx: "15px",
                  width: "1px",
                  height: "20px",
                  backgroundColor: "#000000",
                }}
              />
              <LanguageRounded
                sx={{
                  fontSize: 20,
                  color: colors.textcolor[100],
                  marginRight: "5px",
                }}
              />
              <Typography
                variant="h5"
                fontWeight="bold"
                color={colors.textcolor[100]}
              >
                {countryResponseData}
              </Typography>
            </Box>

            <Box m="0px 1px" display="flex" width="100%" height="100%">
              <FactCard
                title="Lead Status"
                value={leadStatus}
                bgColor="#B39DDB"
                textColor="#311B92"
                icon={<TtyRounded />}
              />

              <FactCard
                title="Assigned On"
                value={assignedOn}
                bgColor="#80CBC4"
                textColor="#004D40"
                icon={<AssignmentRounded />}
              />
              <FactCard
                title="Follow Up Count"
                value={followupCount}
                bgColor="#E6EE9C"
                textColor="#827717"
                icon={<PhoneForwardedRounded />}
              />
              <FactCard
                title="Is Call Scheduled?"
                value={callScheduled}
                bgColor="#FFCC80"
                textColor="#E65100"
                icon={<AlarmRounded />}
              />
            </Box>
          </Box>

          <Box
            m="0px 1px"
            display="flex"
            width="100%"
            maxHeight="70vh"
            justifyContent="space-between"
          >
            {/* {editMode ? (
              <StudentProfileUpdateForm />
            ) : (
              <ProfileCard
                profile={studentProfileData}
                onEditClick={toggleEditMode}
              />
            )} */}

            <Paper style={boxStyle}>
              <Box
                width="100%"
                height="100%"
                borderRadius="15px"
                style={previewBoxStyle}
              >
                <Box style={innerBox}>
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={checkoutSchema}
                    onSubmit={(values, { resetForm }) => {
                      handleFormSubmit(values); // Assuming handleFormSubmit is your submit function
                      resetForm(); // This will reset the form to its initial state
                    }}
                    validator={() => ({})}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      resetForm,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box
                          display="grid"
                          gap="15px"
                          rowGap="35px"
                          gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                          sx={{
                            "& > div": {
                              gridColumn: isNonMobile ? undefined : "span 3",
                            },

                            "& .MuiInputLabel-root": {
                              color: "#333333", // Set the hint text color to dark color
                            },
                            "& input": {
                              color: "#000000",
                              borderRadius: "15px",
                              height: "10px",
                            },

                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              backgroundColor: "#f6f6f6",
                              "& fieldset": {
                                borderColor: "transparent", // Remove border color
                              },
                              "&:hover fieldset": {
                                borderColor: "transparent", // Remove border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "transparent", // Remove border color on focus
                              },
                            },
                          }}
                        >
                          <Box
                            sx={{ gridColumn: "span 1" }}
                            display="flex"
                            alignItems="center"
                          >
                            <IconButton sx={{ color: colors.bg[100] }}>
                              <Person2Outlined />
                            </IconButton>
                            <Typography
                              display="flex"
                              justifyContent="start"
                              variant="h5"
                              fontWeight="bold"
                              color={colors.bg[100]}
                              sx={{ m: "0 0 0 0" }}
                            >
                              Update Call Information
                            </Typography>
                          </Box>

                          <FormControl fullWidth sx={{ gridColumn: "span 1" }}>
                            <InputLabel
                              htmlFor="callStatus"
                              sx={{
                                transform: "translate(0, -25px)",
                                color: "#000000", // Set the label color to black
                              }}
                            >
                              Call Status
                            </InputLabel>
                            <Select
                              fullWidth
                              label="Call Status"
                              name="callStatus"
                              value={values.callStatus}
                              onChange={(e) => {
                                handleChange(e);
                                const selectedCallStatus =
                                  callStatusResponseData.find(
                                    (callStatus) =>
                                      callStatus.id === e.target.value
                                  );
                                setFormValues((prevValues) => ({
                                  ...prevValues,

                                  callStatus: e.target.value,
                                  callStatusName: selectedCallStatus
                                    ? selectedCallStatus.name
                                    : "",
                                }));
                              }}
                              sx={{
                                gridColumn: "span 1",
                                color: "#000000",
                                height: "43px",

                                "& .MuiInputLabel-root": {
                                  transform: "translate(0, -25px)",
                                },
                                "& .MuiSelect-root": {
                                  color: "#000000",
                                  borderRadius: "15px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "10px",
                                  backgroundColor: "#f6f6f6",
                                  "& fieldset": {
                                    borderColor: "transparent",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "transparent",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "transparent",
                                  },
                                },
                                "& .MuiSvgIcon-root": {
                                  color: "#000000", // Set the icon color to black
                                },
                              }}
                              onBlur={handleBlur}
                              error={
                                !!touched.callStatus && !!errors.callStatus
                              }
                              helperText={
                                touched.callStatus && errors.callStatus
                              }
                            >
                              <MenuItem value="" disabled>
                                Select a status
                              </MenuItem>
                              {callStatusResponseData.map((callStat) => (
                                <MenuItem key={callStat.id} value={callStat.id}>
                                  {callStat.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <>
                            {values.callStatus === 5 && (
                              <div>
                                <FormControl
                                  fullWidth
                                  sx={{ gridColumn: "span 1" }}
                                >
                                  <InputLabel
                                    htmlFor="RNT Type"
                                    sx={{
                                      transform: "translate(0, -25px)",
                                      color: "#000000",
                                      // Set the label color to black
                                    }}
                                  >
                                    RNT Reason
                                  </InputLabel>
                                  <Select
                                    fullWidth
                                    label="RNT Reason"
                                    name="rntReason"
                                    value={values.rntReason}
                                    onChange={(e) => {
                                      handleChange(e);
                                      const selectedRntReason =
                                        rntReasonData.find(
                                          (rntreason) =>
                                            rntreason.id === e.target.value
                                        );
                                      setFormValues((prevValues) => ({
                                        ...prevValues,
                                        rntReasonName: selectedRntReason.name,
                                      }));
                                    }}
                                    sx={{
                                      gridColumn: "span 1",
                                      color: "#000000",
                                      height: "43px",

                                      "& .MuiInputLabel-root": {
                                        transform: "translate(0, -25px)",
                                      },
                                      "& .MuiSelect-root": {
                                        color: "#000000",
                                        borderRadius: "15px",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        borderRadius: "10px",
                                        backgroundColor: "#f6f6f6",
                                        "& fieldset": {
                                          borderColor: "transparent",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "transparent",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "transparent",
                                        },
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: "#000000", // Set the icon color to black
                                      },
                                    }}
                                    onBlur={handleBlur}
                                    error={
                                      !!touched.rntReason && !!errors.rntReason
                                    }
                                    helperText={
                                      touched.rntReason && errors.rntReason
                                    }
                                  >
                                    <MenuItem value="" disabled>
                                      Select a reason
                                    </MenuItem>
                                    {rntReasonData.map((reason) => (
                                      <MenuItem
                                        key={reason.id}
                                        value={reason.id}
                                      >
                                        {reason.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            )}
                          </>

                          <>
                            {values.callStatus === 5 &&
                             (
                                <Box
                                  display="grid"
                                  gap="15px"
                                  rowGap="35px"
                                  gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                                  sx={{
                                    "& > div": {
                                      gridColumn: isNonMobile
                                        ? undefined
                                        : "span 3",
                                    },

                                    "& .MuiInputLabel-root": {
                                      color: "#333333", // Set the hint text color to dark color
                                    },
                                    "& input": {
                                      color: "#000000",
                                      borderRadius: "15px",
                                      height: "10px",
                                    },

                                    "& .MuiOutlinedInput-root": {
                                      borderRadius: "10px",
                                      backgroundColor: "#f6f6f6",
                                      "& fieldset": {
                                        borderColor: "transparent", // Remove border color
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "transparent", // Remove border color on hover
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "transparent", // Remove border color on focus
                                      },
                                    },
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="date"
                                    label="Rescheduled Date"
                                    InputLabelProps={{
                                      sx: {
                                        transform: "translate(0, -25px)",
                                      },
                                    }}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFormValues((prevValues) => ({
                                        ...prevValues,
                                        rescheduleDate: e.target.value,
                                      }));
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="end"></InputAdornment>
                                      ),
                                    }}
                                    value={values.rescheduleDate}
                                    name="rescheduleDate"
                                    error={
                                      !!touched.rescheduleDate &&
                                      !!errors.rescheduleDate
                                    }
                                    helperText={
                                      touched.rescheduleDate &&
                                      errors.rescheduleDate
                                    }
                                    sx={{
                                      gridColumn: "span 1",
                                      marginTop: "10px",
                                    }}
                                  />
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="time"
                                    label="Rescheduled Time"
                                    InputLabelProps={{
                                      sx: {
                                        transform: "translate(0, -25px)",
                                      },
                                    }}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFormValues((prevValues) => ({
                                        ...prevValues,
                                        rescheduleTime: e.target.value,
                                      }));
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="end"></InputAdornment>
                                      ),
                                    }}
                                    value={values.rescheduleTime}
                                    name="rescheduleTime"
                                    error={
                                      !!touched.rescheduleDate &&
                                      !!errors.rescheduleDate
                                    }
                                    helperText={
                                      touched.rescheduleTime &&
                                      errors.rescheduleTime
                                    }
                                    sx={{
                                      gridColumn: "span 1",
                                      marginTop: "10px",
                                    }}
                                  />
                                </Box>
                              )}
                          </>

                          <>
                            {(values.callStatus === 1 ||
                              values.callStatus === 2 ||
                              values.callStatus === 3 ||
                              values.callStatus === 4) && (
                              <Box
                                display="grid"
                                gap="15px"
                                rowGap="35px"
                                gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                                sx={{
                                  "& > div": {
                                    gridColumn: isNonMobile
                                      ? undefined
                                      : "span 3",
                                  },

                                  "& .MuiInputLabel-root": {
                                    color: "#333333", // Set the hint text color to dark color
                                  },
                                  "& input": {
                                    color: "#000000",
                                    borderRadius: "15px",
                                    height: "10px",
                                  },

                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: "10px",
                                    backgroundColor: "#f6f6f6",
                                    "& fieldset": {
                                      borderColor: "transparent", // Remove border color
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "transparent", // Remove border color on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "transparent", // Remove border color on focus
                                    },
                                  },
                                }}
                              >
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  type="date"
                                  label="Rescheduled Date"
                                  InputLabelProps={{
                                    sx: {
                                      transform: "translate(0, -25px)",
                                    },
                                  }}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFormValues((prevValues) => ({
                                      ...prevValues,
                                      rescheduleDate: e.target.value,
                                    }));
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="end"></InputAdornment>
                                    ),
                                  }}
                                  value={values.rescheduleDate}
                                  name="rescheduleDate"
                                  error={
                                    !!touched.rescheduleDate &&
                                    !!errors.rescheduleDate
                                  }
                                  helperText={
                                    touched.rescheduleDate &&
                                    errors.rescheduleDate
                                  }
                                  sx={{
                                    gridColumn: "span 1",
                                    marginTop: "10px",
                                  }}
                                />
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  type="time"
                                  label="Rescheduled Time"
                                  InputLabelProps={{
                                    sx: {
                                      transform: "translate(0, -25px)",
                                    },
                                  }}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFormValues((prevValues) => ({
                                      ...prevValues,
                                      rescheduleTime: e.target.value,
                                    }));
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="end"></InputAdornment>
                                    ),
                                  }}
                                  value={values.rescheduleTime}
                                  name="rescheduleTime"
                                  error={
                                    !!touched.rescheduleDate &&
                                    !!errors.rescheduleDate
                                  }
                                  helperText={
                                    touched.rescheduleTime &&
                                    errors.rescheduleTime
                                  }
                                  sx={{
                                    gridColumn: "span 1",
                                    marginTop: "10px",
                                  }}
                                />
                              </Box>
                            )}
                          </>
                          <FormControl fullWidth sx={{ gridColumn: "span 1" }}>
                            <InputLabel
                              htmlFor="possiblility"
                              sx={{
                                transform: "translate(0, -25px)",
                                color: "#000000", // Set the label color to black
                              }} // Set the label color to black
                            >
                              Lead Possiblility
                            </InputLabel>
                            <Select
                              fullWidth
                              label="possiblility"
                              name="possiblility"
                              value={values.possiblility}
                              onChange={(e) => {
                                handleChange(e);
                                setFormValues((prevValues) => ({
                                  ...prevValues,
                                  possiblility: e.target.value,
                                }));
                              }}
                              sx={{
                                gridColumn: "span 1",
                                color: "#000000",
                                height: "43px",

                                "& .MuiInputLabel-root": {
                                  transform: "translate(0, -25px)",
                                },
                                "& .MuiSelect-root": {
                                  color: "#000000",
                                  borderRadius: "15px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "10px",
                                  backgroundColor: "#f6f6f6",
                                  "& fieldset": {
                                    borderColor: "transparent",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "transparent",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "transparent",
                                  },
                                },
                                "& .MuiSvgIcon-root": {
                                  color: "#000000", // Set the icon color to black
                                },
                              }}
                              onBlur={handleBlur}
                              error={
                                !!touched.possiblility && !!errors.possiblility
                              }
                              helperText={
                                touched.possiblility && errors.possiblility
                              }
                            >
                              <MenuItem value="" disabled>
                                Conversion Probability
                              </MenuItem>
                              <MenuItem value="Hot">Hot</MenuItem>
                              <MenuItem value="Warm">Warm</MenuItem>
                              <MenuItem value="Cold">Cold</MenuItem>
                            </Select>
                          </FormControl>

                          <TextField
                            fullWidth
                            type="text"
                            label="Preffered Country"
                            onBlur={handleBlur}
                            InputLabelProps={{
                              sx: {
                                transform: "translate(0, -25px)",
                              },
                            }}
                            onChange={(e) => {
                              handleChange(e);
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                country: e.target.value,
                              }));
                            }}
                            value={values.country}
                            name="country"
                            error={!!touched.country && !!errors.country}
                            helperText={touched.country && errors.country}
                            sx={{ gridColumn: "span 1" }}
                          />
                          <TextField
                            fullWidth
                            type="text"
                            label="Remarks"
                            onBlur={handleBlur}
                            InputLabelProps={{
                              sx: {
                                transform: "translate(0, -25px)",
                              },
                            }}
                            onChange={(e) => {
                              handleChange(e);
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                remarks: e.target.value,
                              }));
                            }}
                            value={values.remarks}
                            name="remarks"
                            error={!!touched.remarks && !!errors.remarks}
                            helperText={touched.remarks && errors.remarks}
                            sx={{ gridColumn: "span 1" }}
                          />
                          <Box display="flex">
                            <Box
                              display="flex"
                              justifyContent="center"
                              mt="0px"
                            >
                              <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                sx={{
                                  borderRadius: "20px", // Set the border radius as needed
                                  backgroundColor: "#000000", // Set the background color to black
                                  color: "#ffffff", // Set the text color to white (optional)
                                  "&:hover": {
                                    backgroundColor: "#333333", // Change the background color on hover
                                  },
                                }}
                              >
                                Update
                              </Button>
                              <Button
                                type="button"
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  giveUpLead(values); // Assuming setFormValues is a function to reset your additional state
                                  resetForm(); // Call resetForm to clear the form
                                }}
                                sx={{
                                  borderRadius: "20px", // Set the border radius as needed
                                  backgroundColor: "#B71C1C", // Set the background color to black
                                  color: "#ffffff", // Set the text color to white (optional)
                                  mx: "10px",
                                  "&:hover": {
                                    backgroundColor: "#333333", // Change the background color on hover
                                  },
                                }}
                              >
                                Give Up
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
        <Box sx={{ flex: "1 0 33.33%", backgroundColor: "#ffffff" }}>
          <Paper style={boxStyle}>
            <Box width="100%" height="100%" borderRadius="15px">
              <Box style={innerBox}>
                <Box
                  sx={{ gridColumn: "span 1" }}
                  display="flex"
                  alignItems="center"
                >
                  <IconButton sx={{ color: colors.bg[100] }}>
                    <Person2Outlined />
                  </IconButton>
                  <Typography
                    display="flex"
                    justifyContent="start"
                    variant="h5"
                    fontWeight="bold"
                    color={colors.bg[100]}
                    sx={{ m: "0 0 0 0" }}
                  >
                    Remarks Summary
                  </Typography>
                </Box>

                <Box width="100%" height="100%" borderRadius="15px">
                  <TimeLine nestedComments={comments} />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
};

export default UserDetails;
