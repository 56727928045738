import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import logoImage from "../tqblogo.svg";
import { useMutation } from "react-query";

import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import { UserLogin } from "../data/repos/userRepo";
import { Formik } from "formik";
import { useMediaQuery } from "@mui/system";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.teqbae.com/">
        REBS
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const [formValues, setFormValues] = useState({});
  const [nameUpdateResponse, setNameUpdateResponse] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [responseData, setResponsedata] = useState({});
  const navigate = useNavigate();

  const mutation = useMutation(UserLogin, {
    onMutate: () => {},
    onSuccess: (data, variables, context) => {
      setResponsedata(data?.responsedata);
      console.log("Response", data?.responsedata);
      const uuid = data?.responsedata.uuid;
      if (uuid !== "") {
        navigate(`/u/${uuid}/dashboard`);
      } else {
        toast.error("Invalid Credentials");
      }
    },
    onError: (error) => {
      // Handle errors here
      console.error("Error:", error.message);
      toast.error(error.message);
    },
  });
  const fetchData = (userData) => {
    mutation.mutate(userData);
  };

  const innerBox = {
    flex: "1",
    margin: "15px",
    boxSizing: "border-box",
    borderRadius: "15px",
  };

  const initialValues = {
    username: "",
    password: "",
  };

  const handleFormSubmit = (values) => {
    const loginobj = {
      username: values.username,
      password: values.password,
    };

    console.log("loginRequestObj:", loginobj);
    fetchData(loginobj);
  };

  return (
    <div
      style={{
        backgroundImage: 'url("bg.jpg")', // Replace with your 3D image URL
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
      elevation={10}
        style={{
          backgroundColor: "white",
          borderRadius: "15px",
          padding: "15px",
          textAlign: "center",
          width: "28vw",
        }}
      >
        <Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            marginBottom="50px"
          >
          <img src={logoImage} alt="Logo" style={{ marginBottom: "30px", marginTop: "30px", width: "300px", height: "auto" }} />

           
            <Box marginLeft="0px">
              <Typography
                display="flex"
                justifyContent="center"
                variant="h5"
                fontWeight="bold"
                color="#000000"
                sx={{ m: "0 0 0 0" }}
              >
                {"Welcome Admin"}
              </Typography>
            </Box>
          </Box>

          <Box style={innerBox}>
            <Formik
              initialValues={initialValues}
              onSubmit={(values, formikBag) => {
                handleFormSubmit(values, formikBag);
              }}
              validator={() => ({})}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="15px"
                    rowGap="35px"
                    gridTemplateColumns="repeat(2, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },

                      "& .MuiInputLabel-root": {
                        color: "#333333", // Set the hint text color to dark color
                      },
                      "& input": {
                        color: "#000000",
                        borderRadius: "15px",
                        height: "10px",
                      },

                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        backgroundColor: "#f6f6f6",
                        "& fieldset": {
                          borderColor: "transparent", // Remove border color
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent", // Remove border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent", // Remove border color on focus
                        },
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      type="text"
                      label="Username"
                      onBlur={handleBlur}
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          username: e.target.value,
                        }));
                      }}
                      value={values.username}
                      name="username"
                      error={!!touched.username && !!errors.username}
                      helperText={touched.username && errors.username}
                      sx={{
                        gridColumn: "span 2",
                      }}
                    />
                    <TextField
                      fullWidth
                      type="password"
                      label="Password"
                      onBlur={handleBlur}
                      InputLabelProps={{
                        sx: {
                          transform: "translate(0, -25px)",
                        },
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          password: e.target.value,
                        }));
                      }}
                      value={values.password}
                      name="password"
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      sx={{
                        gridColumn: "span 2",
                      }}
                    />

                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      sx={{
                        padding: "12px",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "25px",
                        backgroundColor: "#000000",
                        textAlign: "center",

                        color: "#ffffff",
                        "&:hover": {
                          backgroundColor: "#333333",
                        },
                      }}
                    >
                      Login
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Paper>
    </div>
  );
}
